import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../images/logo2.png';

const NotFound = () => {
  return (
    <div className='h-full flex flex-col'>
      <div className='w-full box-border border-b-4 border-blue-900 p-8 bg-blue-900 text-gray-600 text-center mx-auto'>
        <Link to='/'>
          <img src={Logo} className='inline-block' width={180} alt='' />
        </Link>
      </div>
      <div className='flex grow items-center justify-center'>
        <h1 className='text-center text-blue-900 font-extrabold text-8xl'>404</h1>
      </div>
    </div>
  );
};

export default NotFound;
