export default {
  'hero.title': 'Тех Ассист Про - ваш надежный партнер в мире технологий и инноваций.',
  'hero.slogan':
    'Наша компания была основана в 2023 году в Минске, и с тех пор мы стремимся предоставлять высококачественные и интегрированные IT-решения для различных отраслей бизнеса.',
  'hero.button': 'Узнать больше',
  'about.title':
    'Мы специализируемся на оказании услуг IT-поддержки, гарантируя бесперебойную работу вашего бизнеса.',
  'about.text':
    'Наша команда профессионалов обеспечивает оперативное реагирование на технические проблемы и предоставляет поддержку на всех этапах внедрения и использования информационных технологий.',
  'about.text2':
    'Тех Ассист Про также является экспертом в области аналитики, помогая вам принимать обоснованные решения на основе данных. Мы предлагаем услуги KYC (Know Your Customer), обеспечивая безопасность вашего бизнеса и соблюдение законодательства в сфере финансов.',
  'about.button': 'Свяжитесь с нами',
  'service.title': 'Мы обеспечиваем',
  'service.title2': 'Мы создаем',
  'service.text':
    'Наша компания также специализируется в сфере Payment Support и Payment Audit, где мы обеспечиваем надежную и эффективную обработку платежей, минимизируя риски и обеспечивая прозрачность финансовых операций.',
  'service.text2':
    'Тех Ассист Про - мы создаем технологические решения, чтобы ваш бизнес процветал. Доверьтесь нам, и мы сделаем вашу IT-инфраструктуру надежной, эффективной и современной.',
  'cta.title': 'Вы готовы масштабировать свой бизнес?',
  'cta.text': 'Свяжитесь с нами и давайте вместе построим что-то потрясающее!',
  'footer.title.address': 'наш АДРЕС',
  'footer.address': 'г. Минск, пр-т Независимости, 117а',
  'footer.title.phone': 'свяжитесь с  НАМи',
  'footer.title.email': 'ОТПРАВИТЬ E-MAIL',
  copyright: '© {0} Тех Ассист Про. Все права защищены.',
};
