export default {
  'hero.title':
    'Tech Assist Pro is your reliable partner in the world of technology and innovation.',
  'hero.slogan':
    'Our company was founded in 2023 in Minsk, and since then we have been committed to providing high-quality and integrated IT solutions for various business sectors.',
  'hero.button': 'Learn more',
  'about.title':
    'We specialize in providing IT support services, ensuring the smooth operation of your business.',
  'about.text':
    'Our team of professionals ensures prompt response to technical issues and provides support at all stages of IT implementation and utilization.',
  'about.text2':
    'Tech Assist Pro is also an analytics expert, helping you make informed, data-driven decisions. We offer KYC (Know Your Customer) services, ensuring your business is secure and compliant with financial legislation.',
  'about.button': 'Contact us',
  'service.title': 'We provide',
  'service.title2': 'We Make',
  'service.text':
    'Our company also specializes in Payment Support and Payment Audit, where we provide reliable and efficient payment processing, minimizing risk and ensuring transparency in financial transactions.',
  'service.text2':
    'Tech Assist Pro - We create technology solutions to make your business thrive. Trust us to make your IT infrastructure reliable, efficient and modern.',
  'cta.title': 'Are you ready to scale your business?',
  'cta.text': 'Get in touch and let us build something amazing together!',
  'footer.title.address': 'OFFICE ADDRESS',
  'footer.address': 'Minsk, Independence Ave. 117a',
  'footer.title.phone': 'CALL US',
  'footer.title.email': 'SEND EMAIL',
  copyright: '© {0} Tech Assist Pro. All rights reserved.',
};
